import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "../src/assets/Themes/Theme.css";
import "./Helpers/Global/GCss";
import Loading from "./Components/Loading";
// components
const Footer = React.lazy(() => import("./Components/Footer"));
const Header = React.lazy(() => import("./Components/Navbar/Header"));
//pages
const Home = React.lazy(() => import("./Views/Home"));
const About = React.lazy(() => import("./Views/About"));
const Industries = React.lazy(() => import("./Views/Industries"));
const Careers = React.lazy(() => import("./Views/Careers"));
const Portfolio = React.lazy(() => import("./Views/Portfolio"));
const ProductsModule = React.lazy(() => import("./Views/Products"));
const ServicesModule = React.lazy(() => import("./Views/Services"));
const PageNotFound = React.lazy(() => import("./Views/404/PageNotFound"));
const PrivacyPolicy = React.lazy(() => import("./Views/PrivacyPolicy"));
const Contact = React.lazy(() => import("./Views/Contact"));
const BlogModule = React.lazy(() => import("./Views/Blog"));
// styles
const FontStyledContainer = React.lazy(() => import("./Components/FontContainer.styled"));

const LazySuspense = ({ component }) => (
   <Suspense
      fallback={
         <Loading size="6px" color="#0E0E0E" height="70vh" background="white" loading={true} fullScreen>
            module loading...
         </Loading>
      }>
      {/*  Lazy Components */}
      {component}
      {/*  Lazy Components */}
   </Suspense>
);

function App() {
   return (
      <FontStyledContainer>
         <>
            <Header />
            <Routes>
               <Route path="" element={<LazySuspense component={<Home />} />} />
               <Route path="/about-us" element={<LazySuspense component={<About />} />} />
               <Route path="/industries" element={<LazySuspense component={<Industries />} />} />
               <Route path="/career" element={<LazySuspense component={<Careers />} />} />
               <Route path="/blogs*" element={<LazySuspense component={<BlogModule />} />} />
               <Route path="/get-in-touch" element={<LazySuspense component={<Contact />} />} />
               <Route path="/services*" element={<LazySuspense component={<ServicesModule />} />} />
               <Route path="/portfolio" element={<LazySuspense component={<Portfolio />} />} />
               <Route path="/products*" element={<LazySuspense component={<ProductsModule />} />} />
               <Route path="/privacy-policy" element={<LazySuspense component={<PrivacyPolicy />} />} />
               <Route path="*" element={<LazySuspense component={<PageNotFound />} />} />
            </Routes>
            <Footer />
         </>
      </FontStyledContainer>
   );
}

export default App;
