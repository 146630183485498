import { useState } from "react";
import Svg from "../assets/Svgs/Svg";
import { styled } from "styled-components";

const ButtonComp = (props) => {
   const inputProps = props.inputProps;
   const noTransition = props.noTransition;
   const [changebtnArrow, setChangeBtnArrow] = useState(false);

   return (
      <>
         <button
            {...inputProps}
            className={`${noTransition ? "soild-theme-btn witmates-theme-bg" : "btn-theme"} ${props.isDefaultWhite ? "colorWhite" : !changebtnArrow ? "colorBlack" : "colorWhite"}`}
            onMouseEnter={() => setChangeBtnArrow(true)}
            onMouseLeave={() => setChangeBtnArrow(false)}>
            {props.title}
            <span className="ml10">{props.isDefaultWhite ? Svg.longArrowRight : changebtnArrow ? Svg.longArrowRight : Svg.longArrowRightBlack}</span>
         </button>
      </>
   );
};

const SolidButton = ({ event, children, noIcon }) => {
   return (
      <Button className="colorWhite" {...(event ? { onClick: () => event() } : {})}>
         {noIcon ? "" : <span style={{ transform: "rotate(180deg)" }}>{Svg.longArrowRight}</span>}
         &nbsp;{children}
      </Button>
   );
};

const RegularButton = ({ btnText, type, fullWidth, noIcon, disabled }) => {
   return (
      <button className="solid-button-theme" type={type} {...(fullWidth ? { style: { width: "100%" } } : {})} {...(disabled ? { disabled } : {})}>
         {btnText}
         {noIcon ? "" : <span>{Svg.longArrowRightBlack}</span>}
      </button>
   );
};
const Button = styled.button`
   display: flex;
   align-items: center;
   background: var(--colorRed);
   border: none;
   text-transform: uppercase;
   font-weight: 400;
   position: relative;
   padding: 20px 22px 20px 22px;
   z-index: 1;
`;
export { SolidButton };
export { RegularButton };
export default ButtonComp;
