import React from "react";
import styled from "styled-components";

export default function Loading({ size, color, height, background, loading, children, fullScreen }) {
   let LoadingStyleWrapper = styled.div`
      .loading-container {
         background: ${background || "red"};
         width: 100%;
         height: ${height || "50vh"};
      }
      .loader,
      .loader:after {
         border-radius: 50%;
         width: 10em;
         height: 10em;
      }
      .loader {
         margin: 60px auto;
         font-size: ${size ? size : "6px"};
         position: relative;
         text-indent: -1em;
         border-top: 1.1em solid rgba(255, 255, 255, 0.2);
         border-right: 1.1em solid ${color ? color : "black"};
         border-bottom: 1.1em solid ${color ? color : "black"};
         border-left: 1.1em solid ${color ? color : "black"};
         -webkit-transform: translateZ(0);
         -ms-transform: translateZ(0);
         transform: translateZ(0);
         -webkit-animation: load8 700ms infinite linear;
         animation: load8 700ms infinite linear;
      }
      @-webkit-keyframes load8 {
         0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
         }
         100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
         }
      }
      @keyframes load8 {
         0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
         }
         100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
         }
      }
   `;

   return (
      <>
         {loading ? (
            <LoadingStyleWrapper>
               <div className="loading-container d-flex justify-content-center align-items-center">
                  <div className="loader"></div>
               </div>
            </LoadingStyleWrapper>
         ) : (
            children
         )}
      </>
   );
}
export function SkeletonLoading({ theme, loading, children, heights }) {
   const skeletonClass = ["dark", "light"].includes(theme) ? `skeleton-${theme}` : "skeleton-light";

   let LoadingStyleWrapper = styled.div`
      .skeleton-dark {
         background: linear-gradient(90deg, #333 25%, #222 50%, #333 75%);
         background-size: 200% 100%;
         animation: wave-animation 1.5s infinite linear;
      }
      .skeleton-light {
         background: linear-gradient(90deg, #ddd 25%, #ccc 50%, #ddd 75%);
         background-size: 200% 100%;
         animation: wave-animation 1.5s infinite linear;
      }
      /* Optional: Add animation for a loading effect */
      @keyframes wave-animation {
         0% {
            background-position: 200% 0;
         }
         100% {
            background-position: -200% 0;
         }
      }
   `;
   return (
      <>
         {loading ? (
            <LoadingStyleWrapper>
               <div className="p-3">
                  {heights?.map((height, idx) => (
                     <React.Fragment key={`skeleton-${idx}`}>
                        <div class={`${skeletonClass} `} style={{ height, marginBottom: "17px" }}></div>
                     </React.Fragment>
                  ))}
               </div>
            </LoadingStyleWrapper>
         ) : (
            children
         )}
      </>
   );
}
