import { createElement } from "react";

export default function Typography({ element, fontWeight, className, children, color, syne, DMSans }) {
   let defaultClass = syne ? "font-syne" : DMSans ? "font-DMSans" : "";
   switch (fontWeight) {
      case 100:
         defaultClass += " font-weight-100";
         break;
      case 200:
         defaultClass += " font-weight-200";
         break;
      case 300: 
         defaultClass += " font-weight-300";
         break;
      case 400:
         defaultClass += " font-weight-400";
         break;
      case 500:
         defaultClass += " font-weight-500";
         break;
      case 600:
         defaultClass += " font-weight-600";
         break;
      case 700:
         defaultClass += " font-weight-700";
         break;
      case 800:
         defaultClass += " font-weight-800";
         break;
      default:
         break;
   }
   return createElement(
      element,
      {
         className: `${defaultClass} ${className}`,
         style: {
            color: color,
         },
      },
      children
   );
}
