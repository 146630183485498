import React from "react";
import styled from "styled-components";

export default function FontStyledContainer({ children }) {
   const fontLoop = (className) => {
      let fontRange = new Array(100);
      let css = ``;
      for (let index = 0; index < fontRange.length; index++) {
         css += `.${className}-${index + 1} {
              font-size: ${index + 1}px;
           }`;
      }
      return css;
   };

   const lineHeightLoop = (className) => {
      let range = new Array(100);
      let css = ``;
      for (let index = 0; index < range.length; index++) {
         css += `.${className}-${index + 1} {
            line-height: ${index + 1}px;
           }`;
      }
      return css;
   };
   const StyleContainer = styled.div`
      ${fontLoop("font")}
      ${lineHeightLoop("line-height")}
      // XX-Large devices (larger desktops, 1400px and up)
      @media (max-width: 1400px) {
         ${fontLoop("font-xxl")}
         ${lineHeightLoop("line-height-xxl")}
      }
      // X-Large devices (large desktops, 1200px and up)
      @media (max-width: 1200px) {
         ${fontLoop("font-xl")}
         ${lineHeightLoop("line-height-xl")}
      }
      // Large devices (desktops, 992px and up)
      @media (max-width: 992px) {
         ${fontLoop("font-lg")}
         ${lineHeightLoop("line-height-lg")}
      }
      // Medium devices (tablets, 768px and up)
      @media (max-width: 768px) {
         ${fontLoop("font-md")}
         ${lineHeightLoop("line-height-md")}
      }
      @media (max-width: 576px) {
         ${fontLoop("font-sm")}
         ${lineHeightLoop("line-height-sm")}
      }
   `;

   return <StyleContainer>{children}</StyleContainer>;
}
