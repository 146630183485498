import React, { createContext, useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { SolidButton } from "../Components/ButtonComp";
import Typography from "../Components/Typography/Typography";
import Svg from "../assets/Svgs/Svg";
import useMediaQuery from "./useMediaQuery";
import FontStyledContainer from "../Components/FontContainer.styled";
import { useLocation } from "react-router-dom";

const AppContext = createContext({});
function AppProvider({ children }) {
   const [popup, setPopup] = useState({
      isOpen: false,
      content: null,
      cb: null,
   });

   const onClose = () => {
      popup.cb && popup.cb();
      setPopup({
         isOpen: false,
         content: null,
         cb: null,
      });
   };

   // useEffect(() => {
   //    if (window.navigator.userAgent.indexOf("Win") !== -1) {
   //       document.getElementsByTagName("body")[0].style.zoom = "0.9";
   //    }
   //    if (window?.navigator.userAgent.indexOf("Mac") !== -1) {
   //       document.getElementsByTagName("body")[0].style.zoom = "0.9";
   //    }
   //    return () => {};
   // }, []);

   return (
      <AppContext.Provider value={{ setPopup }}>
         {/*  */}
         <Modal show={popup.isOpen} aria-labelledby="contained-modal-title-vcenter" className="backdropClassName" centered onHide={() => onClose()}>
            <FontStyledContainer>
               <ModalContain popup={popup} onClose={onClose} />
            </FontStyledContainer>
         </Modal>
         {children}
         {/*  */}
      </AppContext.Provider>
   );
}

function useApp() {
   return useContext(AppContext);
}
export { AppProvider, useApp };

/*
 _____________________________How to use popup__________________________________
1 import to useApp

2 : const { setPopup } = useApp();

3 : setPopup({
         isOpen: true,
         content: (
            <>
               <h1>jsx</h1>
            </>
         ),
         cb: () => console.log("modal work"),
      });
*/
const ModalContain = ({ popup, onClose }) => {
   const lgBreakpoint = useMediaQuery("(min-width: 991px)");
   const styleModal = {
      minWidth: "896px",
      height: "720px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
   };
   return (
      <div className="p-5" style={{ background: "#000000", ...(lgBreakpoint ? styleModal : {}) }}>
         <div>
            <div className="text-center mb-3">{Svg.done}</div>
            <div className="text-center mb-2">
               <Typography element="span" className="text-white font-64 font-lg-40 text-uppercase" fontWeight={500} DMSans>
                  {popup?.content && popup?.content}
               </Typography>
            </div>
            <div className="d-flex justify-content-center mt-4">
               <SolidButton
                  event={() => {
                     onClose();
                  }}>
                  Back to home
               </SolidButton>
            </div>
         </div>
      </div>
   );
};
